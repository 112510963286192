.provider-stats .databox .databox-left {
    padding: 5px;
    font-size: 16px;
    width: 90px;
}

.provider-stats .databox .databox-right {
    width: -webkit-calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: calc(100% - 90px);
}

.provider-stats .databox .databox-left p:only-child {
    line-height: 58px;
}
